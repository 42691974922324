import 'assets/styles/normalize.scss';
import 'core-js/features/array/find-index';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'whatwg-fetch';
import 'assets/styles/fonts.scss';
import 'assets/styles/themeVariables.scss';

import browserDetect from 'browser-detect';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';

import { NotSupportedModule } from './notSupported';

const browser = browserDetect();

const isOutdatedIE = browser.name === 'ie';
const isOutdatedChrome = browser.name === 'chrome' && browser.versionNumber && browser.versionNumber < 57;
const isOutdatedFirefox = browser.name === 'firefox' && browser.versionNumber && browser.versionNumber < 54;
const isOutdatedSafari = browser.name === 'safari' && browser.versionNumber && browser.versionNumber < 10;
const isOutdatedEdge = browser.name === 'edge' && browser.versionNumber && browser.versionNumber < 16;
const isOutdatedOpera = browser.name === 'opera' && browser.versionNumber && browser.versionNumber < 43;
const isOutdatedBrowser = isOutdatedIE || isOutdatedChrome || isOutdatedFirefox || isOutdatedSafari || isOutdatedEdge || isOutdatedOpera;

const rootEl = document.getElementById('root') as HTMLElement;

configure({ isolateGlobalState: true, enforceActions: 'never' });

if (!isOutdatedBrowser) {
  import('./app')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .then(({ App }) => {
      ReactDOM.render(<App />, rootEl);
    })
    .catch(error => {
      console.error(error);

      if (process.env.NODE_ENV === 'production') {
        window.location.assign('/');
      }
    });
} else {
  ReactDOM.render(<NotSupportedModule />, rootEl);
}

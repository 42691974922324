import React from 'react';

import logo from './assets/img/logo_effie_black.svg';

const style: any = {
  page: {},
  logo: {
    display: 'block',
    margin: '20px auto',
    minWidth: '1px',
  },
  card: {
    display: 'block',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.17)',
    backgroundColor: '#fafafa',
    padding: '12px 20px',
    margin: '20px',
  },
  element: {
    display: 'block',
    margin: '20px auto',
    textAlign: 'center',
  },
};

export const NotSupportedModule = () => (
  <div style={style.page}>
    <div style={style.card}>
      <img style={style.logo} src={logo} alt="logo" />
      <div style={style.element}>Your browser is outdated and not supported. Please, update your browser.</div>
      <div style={style.element}>Ваш браузер устарел и больше не поддерживается! Пожалуйста, обновите ваш браузер.</div>
      <a style={style.element} target="_blank" rel="noopener noreferrer" href="http://outdatedbrowser.com">
        Update / Обновить
      </a>
    </div>
  </div>
);
